<template>
  <div>
    <FlashCards
      :cards="cards"
      :settings="settings"
      @openSettings="openSettingsDialog = true"
    >
    </FlashCards>
    <FlashcardSettings
      v-if="openSettingsDialog"
      :levels="levels"
      :defaultSettings="settings"
      :visible="openSettingsDialog"
      :isGermanSupported="false"
      @changeSettings="updateSettingsAndCloseSettingsDialog"
      @cancel="openSettingsDialog = false"
    />
  </div>
</template>
<script>
import FlashCards from './FlashCards1'
import FlashcardSettings from './FlashcardSettings.vue'
import Utils from '../../util/Utils'
import { mapGetters } from 'vuex'
export default {
  components: { FlashCards, FlashcardSettings },
  data: function () {
    return {
      cards: [
        {
          front: {
            header: '',
            subheader: '',
            image: '',
            speechCode: '',
            speak: ''
          },
          back: {
            header: '',
            subheader: '',
            image: '',
            speechCode: '',
            speak: ''
          }
        }
      ],
      settings: {},
      openSettingsDialog: true
    }
  },
  computed: {
    ...mapGetters(['supportedLangs']),

    selectedCardType() {
      const path = this.$route.path.split('/')
      const category = path[path.length - 1]
      return this.$store.state.practicePlayPages.find(
        (p) => p.categoryId === 'flashcards' && p.id === category
      )?.id
    },
    cardByLevels() {
      return Utils.groupBy(
        this.$store.state.simpleFlashcards[this.selectedCardType],
        (card) => card.level
      )
    },
    levels() {
      const levels = []
      this.cardByLevels.forEach((values, keys) => {
        levels.push({ type: keys, value: keys })
      })
      return levels
    },

    frontCardLang() {
      return this.reverse ? 'gu' : this.selectedLanguage
    },
    backCardLang() {
      return this.reverse ? this.selectedLanguage : 'gu'
    }
  },
  beforeMount() {
    this.selectedLevel = this.levels[0].value
    this.updateSettings({
      level: this.levels[0].value,
      reverse: false, //false=guj in front true=guj in back
      lang: 'en',
      voiceGender: 'FEMALE',
      voiceSpeed: 0.75
    })
    this.buildCards()
  },
  methods: {
    updateSettings(newSettings) {
      this.settings = {
        level: newSettings.level,
        reverse: newSettings.reverse, //false=guj in front true=guj in back
        lang: newSettings.lang,
        voiceGender: newSettings.voiceGender,
        voiceSpeed: newSettings.voiceSpeed
      }

      this.buildCards()
    },
    updateSettingsAndCloseSettingsDialog(newSettings) {
      this.updateSettings(newSettings)
      this.openSettingsDialog = false
    },
    buildCards() {
      this.cards = this.cardByLevels.get(this.settings.level).map((card) => {
        const tempFrontSideData = this.settings.reverse ? 'back' : 'front'
        const tempBackSideData = this.settings.reverse ? 'front' : 'back'

        return {
          serial: card.serial,
          front: {
            header: card[tempFrontSideData].header,
            subheader: card[tempFrontSideData].subheader[this.settings.lang],
            image: this.calculatedImageId(card),
            speechCode: 'gu-IN',
            speak: card[tempFrontSideData].header
          },
          back: {
            header: card[tempBackSideData].header,
            subheader: card[tempBackSideData].subheader[this.settings.lang],
            image: this.calculatedImageId(card),
            speechCode: 'gu-IN',
            speak: card[tempBackSideData].header
          }
        }
      })
    },

    calculatedImageId(card) {
      return Utils.imgUrlByPath(`flashcards/${card.serial}.png`)
    }
  }
}
</script>
<style scoped></style>
