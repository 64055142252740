var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-carousel',{ref:"carousel",staticClass:"customArrow",style:({ width: _vm.$vuetify.breakpoint.mdAndUp ? '450px' : '425px' }),attrs:{"continuous":false,"hide-delimiters":"","height":"550px"}},_vm._l((_vm.flashCards),function(card,i){return _c('v-carousel-item',{key:'carouselItem' + card.serial},[_c('div',{staticClass:"scene pa-5"},[_c('div',{ref:'card' + card.serial,refInFor:true,staticClass:"card"},[_c('audio',{attrs:{"id":"localaudio"},on:{"ended":function($event){_vm.voiceIcon = 'mdi-play'}}}),_c('div',{staticClass:"card__face card__face--front"},[_c('FlashCard',{ref:_vm.selectedCardType + i + 'front',refInFor:true,style:(card.side !== 'front' && card.side !== 'both'
                    ? { display: 'none' }
                    : {}),attrs:{"cardHeader":card.front.header,"cardSubHeader":card.front.subheader,"cardSpeak":card.front.speak,"imgUrl":card.front.image,"voiceIcon":_vm.voiceIcon},on:{"turn":function($event){return _vm.toggleCard('card' + card.serial, i)},"play":_vm.play,"openSettings":function($event){return _vm.$emit('openSettings')}}})],1),_c('div',{staticClass:"card__face card__face--back"},[_c('FlashCard',{ref:_vm.selectedCardType + i + 'back',refInFor:true,style:(card.side !== 'back' && card.side !== 'both'
                    ? { display: 'none' }
                    : {}),attrs:{"voiceIcon":_vm.voiceIcon,"cardHeader":card.back.header,"cardSubHeader":card.back.subheader,"cardSpeak":card.back.speak,"imgUrl":card.back.image},on:{"turn":function($event){return _vm.toggleCard('card' + card.serial, i)},"play":_vm.play,"openSettings":function($event){return _vm.$emit('openSettings')}}})],1)])])])}),1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('div',[_c('v-icon',{style:({ width: '25%' }),attrs:{"medium":"","color":_vm.selectedIndex === 0 ? 'gray' : 'primary'},on:{"click":function($event){return _vm.previous()}}},[_vm._v("mdi-arrow-left")]),_c('v-btn',{style:({
          width: '50%',
          pointerEvents: 'none',
          textTransform: 'none'
        }),attrs:{"text":""}},[_vm._v("Card "+_vm._s(_vm.selectedIndex + 1 + '/' + _vm.cards.length))]),_c('v-icon',{style:({ width: '25%' }),attrs:{"medium":"","color":_vm.selectedIndex === _vm.cards.length - 1 ? 'gray' : 'primary'},on:{"click":function($event){return _vm.next()}}},[_vm._v("mdi-arrow-right")]),_c('GoBackButton')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }